
import NavBar from '../components/NavBar'
import TabBar from '../components/TabBar'
import Foot from '../components/Foot'
export default {
  components: {
    NavBar,
    TabBar,
    Foot
  },
  // UPDATE STORE PRODUCTS
  async fetch () {
    // await this.$store.dispatch('GET_WebsiteData')
    const { data } = await this.$axios.get('/website')
    this.$store.commit('SET_Categories', data.categories)
    this.$store.commit('SET_Collections', data.collections)
    this.$store.commit('SET_Features', data.features)
    this.$store.commit('SET_Exchange', data.exchange)
    this.$store.commit('SET_Banners', data.banners)
  },
  data () {
    return {
      navbar: null
    }
  },
  beforeMount () {
    // window.addEventListener('scroll', this.scrollChecker)
  },
  mounted () {
    this.navbar = this.$el.querySelector('.navbar')
    this.scrollChecker()
    this.$store.commit('INIT_SET_Store')
    // this.$auth.logout()
  },
  activated () {
    // Call fetch again if last fetch more than 30 sec ago
    if (this.$fetchState.timestamp <= Date.now() - 10000) {
      this.$fetch()
    }
  },
  updated () {
    this.$fetch()
  },
  methods: {
    scrollChecker () {
      if (window.innerWidth > 400) {
        if (window.pageYOffset > 0) {
          this.navbar.classList.add('navBg')
        } else {
          this.navbar.classList.remove('navBg')
        }
      }
    }
  }
}
