import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2ade1c16 = () => interopDefault(import('../pages/about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _5951a958 = () => interopDefault(import('../pages/cart.vue' /* webpackChunkName: "pages/cart" */))
const _fff54358 = () => interopDefault(import('../pages/categories.vue' /* webpackChunkName: "pages/categories" */))
const _0aa2ca7d = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _b619c940 = () => interopDefault(import('../pages/orders/index.vue' /* webpackChunkName: "pages/orders/index" */))
const _c85361c2 = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _3b5bb64c = () => interopDefault(import('../pages/signin.vue' /* webpackChunkName: "pages/signin" */))
const _122f0960 = () => interopDefault(import('../pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _075075c8 = () => interopDefault(import('../pages/orders/_id.vue' /* webpackChunkName: "pages/orders/_id" */))
const _092e58a6 = () => interopDefault(import('../pages/products/_category/index.vue' /* webpackChunkName: "pages/products/_category/index" */))
const _1ada7168 = () => interopDefault(import('../pages/reset-password/_token.vue' /* webpackChunkName: "pages/reset-password/_token" */))
const _25265018 = () => interopDefault(import('../pages/products/_category/_id/index.vue' /* webpackChunkName: "pages/products/_category/_id/index" */))
const _d284cc4c = () => interopDefault(import('../pages/products/_category/_id/_product.vue' /* webpackChunkName: "pages/products/_category/_id/_product" */))
const _d2a0ea6c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _2ade1c16,
    name: "about-us"
  }, {
    path: "/cart",
    component: _5951a958,
    name: "cart"
  }, {
    path: "/categories",
    component: _fff54358,
    name: "categories"
  }, {
    path: "/forgot-password",
    component: _0aa2ca7d,
    name: "forgot-password"
  }, {
    path: "/orders",
    component: _b619c940,
    name: "orders"
  }, {
    path: "/products",
    component: _c85361c2,
    name: "products"
  }, {
    path: "/signin",
    component: _3b5bb64c,
    name: "signin"
  }, {
    path: "/signup",
    component: _122f0960,
    name: "signup"
  }, {
    path: "/orders/:id",
    component: _075075c8,
    name: "orders-id"
  }, {
    path: "/products/:category",
    component: _092e58a6,
    name: "products-category"
  }, {
    path: "/reset-password/:token?",
    component: _1ada7168,
    name: "reset-password-token"
  }, {
    path: "/products/:category/:id",
    component: _25265018,
    name: "products-category-id"
  }, {
    path: "/products/:category/:id/:product",
    component: _d284cc4c,
    name: "products-category-id-product"
  }, {
    path: "/",
    component: _d2a0ea6c,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
